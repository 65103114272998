"use client";
import { cva } from "class-variance-authority";
import { clsx } from "clsx";
import { type FC, type ReactElement } from "react";
import { FontWeights, RadiusSizes, TextSizes } from "../../constants";
import { FontWeightType, SizeType } from "../../types";
import { UseFormRegister, FieldValues } from "react-hook-form";

const labelVariants = cva("", {
  variants: {
    labelWeight: FontWeights,
    labelSize: TextSizes,
  },
});

const inputVariants = cva("border placeholder-input-placeholder outline-none w-full", {
  variants: {
    variant: {
      dark: "border-input-dark-border",
      light: "border-input-light-border",
    },
    radius: RadiusSizes,
    size: {
      xs: ["text-sm", "py-3", "px-5"],
      sm: ["text-sm2", "py-2", "px-2"],
      md: ["text-md", "py-4", "px-5"],
      lg: ["text-lg", "py-3", "px-5"],
      xl: ["text-xl", "py-3", "px-5"],
    },
  },
});

interface InputProps {
  variant?: "dark" | "light";
  font?: string;
  type?: string;
  labelWeight?: FontWeightType;
  labelSize?: SizeType;
  name: string;
  value?: string;
  label?: string;
  placeholder?: string;
  radius?: SizeType;
  size?: SizeType;
  disabled?: boolean;
  id?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  register?: UseFormRegister<FieldValues>;
  errors?: FieldValues;
  defaultValue?: string;
  leftText?: string;
  rightText?: string;
  isDisabled?: boolean;
  onBlur?: (e:any) => void;
}

export const Input: FC<InputProps> = ({
  variant = "dark",
  type = "text",
  font,
  value,
  register,
  name,
  label,
  labelWeight,
  disabled,
  labelSize,
  placeholder,
  radius,
  size,
  id,
  onChange,
  onClick,
  defaultValue,
  leftText,
  rightText,
  isDisabled,
  onBlur,
}): ReactElement => {
  return (
    <div className="flex h-max flex-col w-full select-none ">
      {label && (
        // @ts-ignore
        <label htmlFor={id} className={`text-sm2 font-semibold ${clsx(labelVariants({ labelWeight, labelSize }), font)}`}>
          {label}
        </label>
      )}
      {leftText || rightText ? (
        // @ts-ignore
        <div className={`flex ${clsx(inputVariants({ variant, radius, size }), font)} !px-0 !py-0 w-full`}>
          {leftText && <h4 className="whitespace-nowrap border-r-2 text-sm2 p-2 font-semibold w-max">{leftText}</h4>}
          <input
            type={type}
            {...(register ? { ...register(name) } : {})}
            defaultValue={defaultValue}
            onClick={onClick}
            // @ts-ignore
            className={`border-none ${clsx(inputVariants({ variant, radius, size }), font)} ${disabled ? "text-gray-500" : ""}`}
            value={value}
            id={id}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            style={{ backgroundColor: disabled ? "#F9F9FB" : "white" }}
          />
          {rightText && <h4 className="border-r-2 text-sm p-2 font-semibold">{rightText}</h4>}
        </div>
      ) : (
        <input
          type={type}
          {...(register ? { ...register(name) } : {})}
          defaultValue={defaultValue}
          onClick={onClick}
          // @ts-ignore
          className={clsx(inputVariants({ variant, radius, size }), font) + ` ${disabled ? "text-gray-500" : ""}`}
          value={value}
          id={id}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          disabled={isDisabled ? isDisabled : disabled}
          style={{ backgroundColor: disabled ? "#F9F9FB" : "white" }}
          onBlur={onBlur}
        />
      )}
    </div>
  );
};
