import { color } from "framer-motion";
import { notFound } from "next/navigation";

export const config = {
  fizios: {
    name: "Fizios",
    logo: "/assets/fizios/logo.svg",
    favicon: "/assets/fizios/favicon.ico",
    singular: "Pacijent",
    plural: "Pacijenti",
    whom: "pacijenta",
    whomPlural: "pacijente",
    allowedDomains: [
      "app.fizios.ba",
      "fizios.localhost:3000",
      "fizios.localhost:3001",
      "fizios.localhost:3002",
      "localhost:3000",
      "fizios.rezervator.ba",
      "fizios.dev.rezervator.ba",
    ],
    features: {
      bodyFeature: {
        visible: true,
        rotation: true,
        svgMaps: [`/assets/maps/body-front-map.svg`, `/assets/maps/body-back-map.svg`],
        problemTypes: [
          {label: 'Bol', icon: '/assets/icons/pain-icon.svg', color: '#588782'},
          {label: 'Povreda', icon: '/assets/icons/injury-icon.svg', color: '#F24E1E'},
          {label: 'Istegnuće', icon: '/assets/icons/strain-icon.svg', color: '#799BF9'},
          {label: 'Ostalo', icon: '/assets/icons/other-problem-icon.svg', color: '#000000DE'},
        ]
      },
      // theethFeature: {
      //   visible: false,
      //   rotation: false,
      //   svgMaps: ['/assets/maps/theeth-map.svg'],
      //   problemTypes: [
      //     {label: 'Bol', icon: '/assets/icons/pain-icon.svg', color: '#588782'},
      //     {label: 'Karijes', icon: '/assets/icons/caries-icon.svg', color: '#F24E1E'},
      //     {label: 'Puknuće', icon: '/assets/icons/rupture-icon.svg', color: '#799BF9'},
      //     {label: 'Ostalo', icon: '/assets/icons/other-problem-icon.svg', color: '#000000DE'},
      //   ],
      // }
    }
  },
  stomatolog: {
    name: "Stomatolog",
    logo: "/assets/stomatolog/logo.png",
    favicon: "/assets/stomatolog/favicon.ico",
    singular: "Pacijent",
    plural: "Pacijenti",
    whom: "pacijenta",
    whomPlural: "pacijente",
    allowedDomains: [
      "app.fizios.ba",
      "stomatolog.localhost:3000",
      "localhost:3000",
      "stomatolog.rezervator.ba",
      "stomatolog.dev.rezervator.ba",
    ],
    features: {
      // bodyFeature: {
      //   visible: true,
      //   rotation: true,
      //   svgMaps: [`/assets/maps/body-front-map.svg`, `/assets/maps/body-back-map.svg`],
      //   problemTypes: [
      //     {label: 'Bol', icon: '/assets/icons/pain-icon.svg', color: '#588782'},
      //     {label: 'Povreda', icon: '/assets/icons/injury-icon.svg', color: '#F24E1E'},
      //     {label: 'Istegnuće', icon: '/assets/icons/strain-icon.svg', color: '#799BF9'},
      //     {label: 'Ostalo', icon: '/assets/icons/other-problem-icon.svg', color: '#000000DE'},
      //   ]
      // },
      theethFeature: {
        visible: false,
        rotation: false,
        svgMaps: ['/assets/maps/theeth-map.svg'],
        problemTypes: [
          {label: 'Bol', icon: '/assets/icons/pain-icon.svg', color: '#588782'},
          {label: 'Karijes', icon: '/assets/icons/caries-icon.svg', color: '#F24E1E'},
          {label: 'Puknuće', icon: '/assets/icons/rupture-icon.svg', color: '#799BF9'},
          {label: 'Ostalo', icon: '/assets/icons/other-problem-icon.svg', color: '#000000DE'},
        ],
      }
    }
  },
  sandra: {
    name: "Sandra",
    logo: "/assets/sandra/logo.svg",
    favicon: "/assets/sandra/favicon.ico",
    singular: "Klijent",
    plural: "Klijenti",
    whomPlural: "klijente",
    whom: "klijenta",
    allowedDomains: [
      "app.fizios.ba",
      "sandra.localhost:3000",
      "localhost:3000",
      "sandra.rezervator.ba",
      "sandra.dev.rezervator.ba",
    ],
    features: {}
  },
  alphabarbershop: {
    name: "Alpha Barber Shop",
    logo: "/assets/alphabarbershop/logo.png",
    favicon: "/assets/alphabarbershop/favicon.ico",
    singular: "Klijent",
    plural: "Klijenti",
    whomPlural: "klijente",
    whom: "klijenta",
    allowedDomains: [
      "app.alphabarbershop.ba",
      "alphabarbershop.localhost:3000",
      "localhost:3000",
      "alphabarbershop.rezervator.ba",
      "alphabarbershop.dev.rezervator.ba",
    ],
    features: {}
  },
  'madamm-amela': {
    name: "Madamm Amela",
    logo: "/assets/madamm-amela/logo.png",
    favicon: "/assets/madamm-amela/favicon.ico",
    singular: "Klijent",
    plural: "Klijenti",
    whomPlural: "klijente",
    whom: "klijenta",
    allowedDomains: [
      "app.fizios.ba",
      "madamm-amela.localhost:3000",
      "localhost:3000",
      "madamm-amela.rezervator.ba",
      "madamm-amela.dev.rezervator.ba",
    ],
    features: {}
  },
  dev: {
    name: "Dev",
    logo: "/assets/dev/logo.svg",
    favicon: "/assets/dev/favicon.ico",
    singular: "Klijent",
    plural: "Klijenti",
    whom: "klijenta",
    whomPlural: "klijente",
    allowedDomains: [
      "dev.fizios.ba",
      "dev.localhost:3000",
      "dev.localhost:3002",
      "localhost:3000",
      "dev.rezervator.ba",
      "dev.rezervator.ba",
    ],
    features: {
      theethFeature: {
        visible: false,
        rotation: false,
        svgMaps: ['/assets/maps/theeth-map.svg'],
        problemTypes: [
          {label: 'Bol', icon: '/assets/icons/pain-icon.svg', color: '#588782'},
          {label: 'Karijes', icon: '/assets/icons/caries-icon.svg', color: '#F24E1E'},
          {label: 'Puknuće', icon: '/assets/icons/rupture-icon.svg', color: '#799BF9'},
          {label: 'Ostalo', icon: '/assets/icons/other-problem-icon.svg', color: '#000000DE'},
        ],
      }
    }
  }
};

export const serverSideConfigLoader = (host: string) => {
  for (const key in config) {
    // @ts-ignore
    const appConfig = config[key];
    if (appConfig.allowedDomains && appConfig.allowedDomains.includes(host)) {
      return appConfig;
    }
  }

  return notFound();
};

export const clientSideConfigLoader = (host: string) => {
  for (const key in config) {
    // @ts-ignore
    const appConfig = config[key];
    if (appConfig.allowedDomains && appConfig.allowedDomains.includes(host)) {
      return appConfig;
    }
  }
  return false;
};
