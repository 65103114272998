import { IGetAllServicesRequest, IGetServiceByRequest } from "../../../types";

const API_PREFIX = "/services";

export const getGetAllServicesEndpoint = (queries: IGetAllServicesRequest) => {
  const { page, pageSize, search } = queries;
  return `${API_PREFIX}${page && pageSize ? `?page=${page}&pageSize=${pageSize}&search=${search ? search : ''}` : ''}`;
}

export const getGetServiceByEndpoint = (getServiceByQueries: IGetServiceByRequest) =>
  `${API_PREFIX}/getBy?prop=${getServiceByQueries.prop}&value=${getServiceByQueries.value}`;
export const getUpdateServiceEndpoint = (serviceId: string) => `${API_PREFIX}/${serviceId}`;
export const getDeleteServiceEndpoint = (serviceId: string) => `${API_PREFIX}/${serviceId}`;

export const getCreateServiceEndpoint = () => `${API_PREFIX}`;
