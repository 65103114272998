'use client'
import { Providers } from "../utils";
import "./global.css";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import { clientSideConfigLoader } from "@/config";
import { useGlobalStore } from "@/zustand/initStore";

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const [domain, setDomain] = useState<string>("");
  const { config, setConfig } = useGlobalStore();
  console.log('Version 1.3.0 ');
  useEffect(() => {
    if (typeof window !== "undefined") {
      const host = window.location.hostname;
      setDomain(host);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const host = window.location.host;
      const loadedConfig = clientSideConfigLoader(host);
      if (loadedConfig) {
        setConfig(loadedConfig);
      } else {
        window.location.assign("https://rezervator.ba");
      }
    }
  }, [setConfig]);

  return (
    <html id='root' lang="en">
      <head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        {config?.favicon && (
          <link rel="icon" href={config.favicon as unknown as string} />
        )}
      </head>
      <body data-domain={domain}>
        <Providers>{children}</Providers>
      </body>
    </html>
  );
}
