import { toast } from "react-toastify";
import { IGetOccupancyResponseMonthly, IGetOccupancyResponseYearly, IReservation, IService, IUser, IWorkingHours, StatsPeriodType } from "../../types";
import { z } from 'zod';
interface DataPoint {
  name: string;
  [key: string]: any;
}
const currentDate = new Date();
const todayYear = currentDate.getFullYear();
const todayMonth = currentDate.getMonth();
const todayDate = currentDate.getDate();

export function formatChartData(data: any, selectedType: string) {
  const formattedData: DataPoint[] = [];
  const legend: string[] = [];
  if (selectedType === "YEAR") {
    Object.keys(data).forEach((month) => {
      const monthData = data[month];
      if (Array.isArray(monthData)) {
        monthData.forEach((item, index) => {
          const key = `${item.serviceName || item.staffName}`;
          if (!legend.includes(key)) {
            legend.push(key);
          }
          const existingData = formattedData.find((d) => d.name === month);
          if (existingData) {
            existingData[key] = item.profit;
          } else {
            formattedData.push({ name: month, [key]: item.profit });
          }
        });
      } else {
        formattedData.push({ name: month, prihod: monthData });
      }
    });
  } else {
    Object.keys(data).forEach((date) => {
      const dateData = data[date];
      if (Array.isArray(dateData)) {
        dateData.forEach((item, index) => {
          const key = `${item.serviceName || item.staffName}`;
          if (!legend.includes(key)) {
            legend.push(key);
          }
          const existingData = formattedData.find((d) => d.name === date);
          if (existingData) {
            existingData[key] = item.profit;
          } else {
            formattedData.push({ name: date, [key]: item.profit });
          }
        });
      } else {
        formattedData.push({ name: date, prihod: dateData });
      }
    });
  }

  return { formattedData, legend };
}

export const formatServiceGraphData = (data: any, period: string, selectedServiceId: string[]) => {
  if (!data) return [];
  const days = ['Ned', 'Pon', 'Uto', 'Sri', 'Čet', 'Pet', 'Sub'];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];
  let formattedChartData;

  if (selectedServiceId.length > 0) {
    formattedChartData = Object.keys(data).map(key => {
      const date = period === 'DAILY' ? 
      `${days[new Date(key).getDay()]} - ${formatDateShort(new Date(key))}`
      : 
      (period === 'MONTHLY' ? months[Number(key.split('/')[0]) - 1] : key);
      const entry: { name: string; [key: string]: any } = { name: date };
      
      if (Array.isArray(data[key])) {
        data[key].forEach((item: any) => {
          entry[item.serviceName] = item.appointments;
        });
      } else if (typeof data[key] === 'object') {
        entry[data[key].serviceName] = data[key].appointments;
      }
      
      return entry;
    });
  } else {
    formattedChartData = Object.keys(data).map(key => {
      return {
        name: period === 'DAILY' 
        ? `${days[new Date(key).getDay()]} - ${formatDateShort(new Date(key))}`
        : (period === 'MONTHLY' ? months[Number(key.split('/')[0]) - 1] : key),
        value: Array.isArray(data[key]) 
          ? data[key].reduce((sum: number, item: any) => sum + item.appointments, 0) 
          : (typeof data[key] === 'object' ? data[key].appointments : data[key])
      };
    });
  }
  return formattedChartData;
}

export const formatGraphData = (data: any, period: string, selectedEmployeeId: string[]) => {
  if (!data) return [];
  const days = ['Ned', 'Pon', 'Uto', 'Sri', 'Čet', 'Pet', 'Sub'];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];
  let formattedChartData;
  if (selectedEmployeeId.length > 0) {
    formattedChartData = Object.keys(data).map(key => {
      const date = period === 'DAILY' ? 
      `${days[new Date(key).getDay()]} - ${formatDateShort(new Date(key))}` 
      : 
      (period === 'MONTHLY' ? months[Number(key.split('/')[0]) - 1] : key);
      const entry: { name: string; [key: string]: any } = { name: date };
      data[key].forEach((item: any) => {
          entry[item.staff] = item.occupancy;
        }
      );
      return entry;
    });
  } else {
    formattedChartData = Object.keys(data).map(key => {
      return {
        name: period === 'DAILY' ? 
        `${days[new Date(key).getDay()]} - ${formatDateShort(new Date(key))}` 
        : 
        (period === 'MONTHLY' ? months[Number(key.split('/')[0]) - 1] : key),
        value: data[key]
      };
    });
  }
  return formattedChartData;
}

export function formatDate(dateString: string) {
  const date = new Date(dateString);
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1; // Mjeseci su indeksirani od 0
  const year = date.getUTCFullYear();
  return `${day}.${month}.${year}.`;
}

export function htmlToPlainText(html: string) {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = html;
  return tempElement.textContent || tempElement.innerText || "";
}

export function calculateTimeDifference(startTime: string, endTime: string) {
  // Razdvajanje sati i minuta
  const [startHours, startMinutes] = startTime.split(':').map(Number);
  const [endHours, endMinutes] = endTime.split(':').map(Number);

  // Pretvaranje vremena u minute
  const startTotalMinutes = startHours * 60 + startMinutes;
  const endTotalMinutes = endHours * 60 + endMinutes;

  // Izračunavanje razlike u minutama
  const differenceInMinutes = endTotalMinutes - startTotalMinutes;

  return differenceInMinutes;
}

export function addMinutesToTime(startTime: string, minutesToAdd: number) {
  // Razdvajanje sati i minuta
  const [startHours, startMinutes] = startTime.split(':').map(Number);

  // Pretvaranje početnog vremena u minute
  const startTotalMinutes = startHours * 60 + startMinutes;

  // Dodavanje minuta
  const newTotalMinutes = startTotalMinutes + minutesToAdd;

  // Pretvaranje natrag u sate i minute
  let newHours = Math.floor(newTotalMinutes / 60) % 24; // % 24 za slučaj prelaska preko ponoći
  let newMinutes = newTotalMinutes % 60;
  newHours = newHours < 0 ? (-1)*newHours : newHours
  newMinutes = newMinutes < 0 ? (-1)*newMinutes : newMinutes
  // Formatiranje rezultata u "HH:MM"
  const formattedHours = String(newHours).padStart(2, '0');
  const formattedMinutes = String(newMinutes).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
}




export const translator: { [key: string]: string } = {
  monday: "Ponedjeljak",
  tuesday: "Utorak",
  wednesday: "Srijeda",
  thursday: "Četvrtak",
  friday: "Petak",
  saturday: "Subota",
  sunday: "Nedjelja",
};

export const formatBSDate = (dateString: Date) => {
  const date = new Date(dateString);

  // Format the date using hr-HR locale
  let formattedDate = date.toLocaleDateString("hr-HR", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  // Define a mapping of Croatian month names to Bosnian month names
  const monthMapping: { [key: string]: string } = {
    siječnja: "Januar",
    veljače: "Februar",
    ožujka: "Mart",
    travnja: "April",
    svibnja: "Maj",
    lipnja: "Juni",
    srpnja: "Juli",
    kolovoza: "August",
    rujna: "Septembar",
    listopada: "Oktobar",
    studenoga: "Novembar",
    prosinca: "Decembar",
  };

  // Replace Croatian month names with Bosnian month names
  formattedDate = formattedDate.replace(
    /\b(?:siječnja|veljače|ožujka|travnja|svibnja|lipnja|srpnja|kolovoza|rujna|listopada|studenoga|prosinca)\b/gi,
    (matched) => monthMapping[matched.toLowerCase()]
  );

  // Capitalize the first letter of each word in formattedDate
  formattedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

  return formattedDate;
};

export const getCurrentDayReservations = (data: IReservation[]): number => {
  if (!data) return 0;
  const filteredData = data?.filter((item) => {
    const itemDate = new Date(item.date);
    return itemDate.getFullYear() === todayYear && itemDate.getMonth() === todayMonth && itemDate.getDate() === todayDate;
  });

  return filteredData.length || 0;
};

export const getWeeklyIncome = (data: IReservation[]) => {
  let sum = 0;
  const firstDayOfWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1));
  const lastDayOfWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 6));

  const filteredData = data?.filter((item) => {
    const itemDate = new Date(item.date);
    return itemDate >= firstDayOfWeek && itemDate <= lastDayOfWeek;
  });

  filteredData?.forEach((filteredItem) => {
    sum += +filteredItem.services[0].price;
  });

  return sum;
};

export const getMonthlyIncome = (data: IReservation[]) => {
  let sum = 0;

  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

  const filteredData = data?.filter((item) => {
    const itemDate = new Date(item.date);
    return itemDate >= firstDayOfMonth && itemDate <= lastDayOfMonth;
  });

  filteredData?.forEach((filteredItem) => {
    sum += +filteredItem.services[0].price;
  });

  return sum;
};

export const generateYearArray = () => {
  const currentYear = new Date().getFullYear();
  const yearsArray = [];

  for (let i = 0; i <= 30; i++) {
    const year = currentYear - i;
    yearsArray.push({ value: year, label: year + " g." });
  }

  return yearsArray;
};

export const formatPrice = (price: number) => {
  return price
};

export function getTodayDate(): string {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Mjeseci su 0-indeksirani
  const year = today.getFullYear();

  return `${day}.${month}.${year}`;
}

// Define the necessary interfaces if not already defined
interface IGetOccupancyResponseDaily {
  totalOccupancy: { date: number; [key: string]: number }[];
  totalAppointments: { date: number; [key: string]: number }[];
}

interface ChartData {
  date: string;
  value: number;
}

// Type guard to check if the data has the `totalOccupancy` property

function hasTotalOccupancy(data: any): data is IGetOccupancyResponseDaily {
  return data && Array.isArray(data.totalOccupancy);
}

function hasTotalAppointments(data: any): data is IGetOccupancyResponseDaily {
  return data && Array.isArray(data.totalAppointments);
}

export function getChartDataDaily(data: any): ChartData[] {
  if (!data) return [];

  const chartData: ChartData[] = [];
  const daysOfWeek = ["Ned", "Pon", "Uto", "Sri", "Čet", "Pet", "Sub"]; // Croatian day names

  let arr: { date: number; [key: string]: number }[] = [];

  if (hasTotalOccupancy(data)) {
    arr = data.totalOccupancy;
  } else if (hasTotalAppointments(data)) {
    arr = data.totalAppointments;
  } else {
    return [];
  }

  arr.map((item) => {
    const date = new Date(item.date);
    const dayOfWeek = daysOfWeek[date.getUTCDay()]; // Get the day of the week in Croatian

    chartData.push({
      date: dayOfWeek,
      value: item[Object.keys(item)[0]],
    });
  });

  return chartData;
}

export function getShortMonthName(isoDate: string) {
  const date = new Date(isoDate);
  const shortMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  return shortMonthNames[date.getUTCMonth()];
}

export const getAdjustedDayNumber = (date: Date): number => {
  const dayNumber = date.getDay(); // 0 (Sunday) - 6 (Saturday)
  return ((dayNumber + 6) % 7) + 1; // Adjust so Monday is 1, Sunday is 7
};

export const getRowNumber = (time: string, timeSlot: number, startWorkHour: number): number => {
  console.log(time, timeSlot, startWorkHour, 'vrijeme')
  const [hours, minutes] = time.split(":").map(Number);

  // Početak radnog vremena u minutama (8:00 je početak)
  const startOfWorkdayInMinutes = startWorkHour * 60; // 8 sati x 60 minuta

  // Ukupno vreme u minutama za dati termin
  const totalMinutes = (hours * 60 + minutes);

  // Razlika u minutama od početka radnog vremena
  const minutesFromStartOfWorkday = totalMinutes - startOfWorkdayInMinutes;

  // Računamo redni broj na osnovu vremenskog intervala
  const rowNumber = minutesFromStartOfWorkday / timeSlot;
  console.log(rowNumber, 'redni broj')
  return rowNumber;
};



// ... postojeći kod ...

export function validateTimeChange(
  time: string,
  day: keyof IWorkingHours,
  momentType: "from" | "to" | "break" | "breakDuration",
  workingHours: IWorkingHours
): boolean {
  if (momentType === 'break' || momentType === 'breakDuration') {
    const fromTime = workingHours[day].from;
    const toTime = workingHours[day].to;

    if (momentType === 'breakDuration' && (time < fromTime || time > toTime)) {
      toast.error('Vrijeme pauze mora biti unutar radnog vremena.');
      return false;
    }
    else if (momentType === 'break' && (time < fromTime || time >= toTime)) {
      toast.error('Vrijeme pauze mora biti unutar radnog vremena.');
      return false;
    }
  }

  if (momentType === 'to' && time <= workingHours[day].from) {
    toast.error('Kraj radnog vremena ne smije biti manji ili jednak od početka.');
    return false;
  }

  if (momentType === 'breakDuration' && time <= workingHours[day].break) {
    toast.error('Kraj pauze ne smije biti manji ili jednak od početka.');
    return false;
  }

  // Provjera da li je početak pauze unutar radnog vremena
  if (momentType === 'break' && (time < workingHours[day].from || time >= workingHours[day].to)) {
    toast.error('Početak pauze mora biti unutar radnog vremena.');
    return false;
  }

  // Provjera da li je kraj pauze unutar radnog vremena kada se pomjeri početak pauze
  if (momentType === 'break') {
    const breakDuration = workingHours[day].breakDuration;
    const [breakHours, breakMinutes] = time.split(':').map(Number);
    const breakEndTime = new Date();
    breakEndTime.setHours(breakHours, breakMinutes + Number(breakDuration));

    const [toHours, toMinutes] = workingHours[day].to.split(':').map(Number);
    const endOfWorkTime = new Date();
    endOfWorkTime.setHours(toHours, toMinutes);

    if (breakEndTime > endOfWorkTime) {
      toast.error('Kraj pauze mora biti unutar radnog vremena.');
      return false;
    }
  }

  return true;
}

// ... postojeći kod ...

export const getStartOfWeek = (date: Date) => {
  const start = new Date(date);
  start.setDate(start.getDate() - start.getDay() + 1);
  start.setHours(0, 0, 0, 0);
  return start;
};

export const getEndOfWeek = (date: Date) => {
  const end = new Date(date);
  end.setDate(end.getDate() - end.getDay() + 7);
  end.setHours(23, 59, 59, 999);
  return end;
};

export const formatDateShort = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  return `${day}.${month}.`;
};

export const groupReservations = (reservations: IReservation[], slot: number) => {
  const grouped: { [key: string]: IReservation[] } = {};
  reservations?.forEach((reservation) => {
    const reservationDateTime = reservation.date + ' ' + reservation.time;
    const [reservationDate, reservationTime] = reservationDateTime.split(' ');
    const [reservationHours, reservationMinutes] = reservationTime.split(':').map(Number);
    const reservationDuration = Number(reservation.services[0].duration);
    const numberOfSlots = Math.ceil(reservationDuration / slot);
    for (let i = 0; i < numberOfSlots; i++) {
      const totalMinutes = reservationMinutes + slot * i;
      const adjustedHours = reservationHours + Math.floor(totalMinutes / 60);
      const adjustedMinutes = totalMinutes % 60;
      const newTime = `${String(adjustedHours).padStart(2, '0')}:${String(adjustedMinutes).padStart(2, '0')}`;
      const newDateTime = `${reservationDate} ${newTime}`;
      const newReservation = { ...reservation, newDateTime };
      if (!grouped[newDateTime]) {
        grouped[newDateTime] = [];
      }
      newReservation.newTime = newTime;
      grouped[newDateTime].push(newReservation);
    }
  });
  console.log(Object.values(grouped));
  return Object.values(grouped);
};

// ... postojeći kod ...

export const getLinePosition = (hours: number, minutes: number) => {
  const initialOffset = 28; // Inicijalni pomak u pikselima
  const hourValue = 128; // Vrijednost jednog sata u pikselima
  const minuteValue = 2.13; // Vrijednost jedne minute u pikselima
  const initialHours = 8;

  const totalPixels = initialOffset + (hours-initialHours)*hourValue + minutes*minuteValue;
  return totalPixels;
}

export const statusMap = {
  DONE: { value: "Obavljen", borderColor: "border-l-[#588782]", 'text-color': 'text-primary'},
  CONFIRMED: { value: "Potvrđen", borderColor: "border-l-blue", 'text-color': 'text-blue' },
  CANCELED: { value: "Otkazan", borderColor: "border-l-red-600", 'text-color': 'text-red-600'},
};

export function formatNumber(number: number): string {
  if (!number) return "0,00";

  let [integerPart, decimalPart] = number.toString().split(".");

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  if (!decimalPart) {
    decimalPart = "00";
  } else if (decimalPart.length === 1) {
    decimalPart = `${decimalPart}0`;
  } else if (decimalPart.length > 2) {
    decimalPart = decimalPart.substring(0, 2);
  }

  return `${integerPart},${decimalPart}`;
}

export function formatDiff(num: number) {
  return num.toFixed(2);
}

export function getDayOfMonth(isoDate: string) {
  const date = new Date(isoDate);
  return date.getUTCDate();
}

export function getAllDaysInMonth(isoDate: string) {
  const date = new Date(isoDate);
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth();
  const daysInMonth = new Date(Date.UTC(year, month + 1, 0)).getUTCDate();
  return Array.from({ length: daysInMonth }, (_, i) => i + 1);
}

export const handleEmployeeChange = (
  currentIndex: number,
  employees: IUser[] | IService[],
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>
) => {
  const newIndex = (currentIndex + 1) % employees.length;
  setCurrentIndex(newIndex);
};

const monthMap: { [key: string]: string } = {
  "1": "Jan",
  "2": "Feb",
  "3": "Mar",
  "4": "Apr",
  "5": "May",
  "6": "Jun",
  "7": "Jul",
  "8": "Aug",
  "9": "Sep",
  "10": "Oct",
  "11": "Nov",
  "12": "Dec",
};

export function getChartData(data: IGetOccupancyResponseMonthly | IGetOccupancyResponseYearly | undefined): ChartData[] {
  if (!data) return [];

  const chartData: ChartData[] = [];
  Object.keys(data).map((key) => {
    const [month] = key.split("/");
    const monthName = monthMap[month];
    let formattedDate;
    if (!monthName) formattedDate = month;
    else formattedDate = `${monthName}`;
    chartData.push({
      date: formattedDate,
      value: data[key],
    });
  });

  return chartData;
}

export const StatsTypeMap = {
  DAILY: "Dnevno",
  MONTHLY: "Mjesečno",
  YEARLY: "Godišnje",
};

export const handleChange = (setType: React.Dispatch<React.SetStateAction<StatsPeriodType>>) => {
  setType((prevState) => {
    switch (prevState) {
      case "DAILY":
        return "MONTHLY";
      case "MONTHLY":
        return "YEARLY";
      case "YEARLY":
        return "DAILY";
      default:
        return "DAILY";
    }
  });
};


// validation.ts


export const phoneNumberSchema = z.string().regex(/^\+?[1-9]\d{1,14}$/, { message: "Unesite ispravan broj telefona" });

export const validateField = (fieldName: string, value: string): string | undefined => {
  switch (fieldName) {
    case 'phoneNumber':
      return phoneNumberSchema.safeParse(value).success ? undefined : phoneNumberSchema.safeParse(value).error?.issues[0].message;
    default:
      return undefined;
  }
};

export function formatCurrency(price: string, locale = 'en-US') {
  try {
    const priceNumber = Number(price);
    if(isNaN(priceNumber))
      return null;
    
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    }).format(priceNumber);
  } catch (error: any) {
    console.error(error.message);
    return null;
  }
}

  export const handlePriceBlur = (e: any, setValue: any) => {
    console.log(e.target.value);
    // Formatiraj vrijednost kao valutu
    const formattedValue = formatCurrency(e.target.value);
    console.log(formattedValue);
    // Ažuriraj vrijednost u formi
    setValue("price", formattedValue);

  };